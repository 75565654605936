<template>
  <div class="shopActivity-container">
    <el-row type="flex" justify="end">
		<el-button @click="onAddUser"  icon="el-icon-plus" type="primary">新建用户</el-button>
    </el-row>
    <el-form :inline="true" :model="formInline" style="display: flex;width: 50%;padding-top:10px;" >
        <el-form-item label="关键字" class="row-first">
                    <el-input v-model="formInline.keyWord" placeholder="关键字"></el-input>
        </el-form-item>
        <el-form-item>
                    <el-button type="primary" @click="onSearchByKeyWord">查询</el-button>
        </el-form-item>
    </el-form>

 
    <common-table :data="ul" style="width: 100%;">
                <el-table-column prop="userName" align="center" label="用户名"></el-table-column>
                <el-table-column  prop="name" align="center" label="姓名"></el-table-column>
                <el-table-column  prop="telNo" align="center" label="电话"></el-table-column>
                <el-table-column prop="mail" align="center" label="邮箱"></el-table-column>
                <!-- <el-table-column prop="companyName"  align="center" label="单位"></el-table-column> -->
                <el-table-column prop="roleName"  width="100" align="center" label="角色"></el-table-column>
                <el-table-column  prop="rowTime"  align="center" label="创建日期"></el-table-column>
                <el-table-column  prop="operation" width="130" align="center" label="管理">
                    <template v-slot="scope">
                        <el-button @click.native.prevent="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click.native.prevent="handleResetPassword(scope.row)" type="text" size="small">重置</el-button>
                        <el-button @click.native.prevent="handleDelete(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
    </common-table>   

    <el-dialog title="编辑用户"  v-model="editUserDialogFormVisible" :modal-append-to-body='false'
                             :close-on-click-modal='false'
                             append-to-body>
            <el-form :inline="true" :model="editUserForm">
                <el-form-item label="用户名" label-width="100px">
                    <el-input v-model="editUserForm.userName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="姓名" label-width="100px">
                    <el-input v-model="editUserForm.name" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="电话" label-width="100px">
                    <el-input v-model="editUserForm.telNo" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱"  label-width="100px">
                    <el-input v-model="editUserForm.mail" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="单位/角色" label-width="100px">
                    <el-col :span="24">
                        <el-cascader
                            v-model="value"
                            style="width:400px"
                            :options="companyRoleList"
                            @change="handleCRChange"></el-cascader>
                    </el-col>
                </el-form-item>  -->

                 <!-- <el-row>
                <el-form-item  label="部门" label-width="100px">
                    <el-cascader  style="width:525px;" :placeholder="holdertext"
							:value="editUserForm.departmentIdArrayForForm"
                            @change="onDepartmentListItemChooseChange"
                            :options="departmentList"
                            :props="{ checkStrictly: true }"
                            ></el-cascader>
                </el-form-item>
                </el-row> -->
                <el-row>
                <el-form-item label="角色" label-width="100px">
                    <el-select style="width:300px;" v-model="editUserForm.roleId" placeholder="请选择角色">
                        <el-option
                                v-for="item in userSettingRoleList"
                                :key="item.roleId"
                                :label="item.roleName"
                                :value="item.roleId">
                        </el-option>
                    </el-select>
                </el-form-item>
                </el-row>

            </el-form>
            <div style="display:flex;justify-content:center">
                <el-button @click="editUserDialogFormVisible = false">取 消</el-button>
                <el-button :loading="editUserDialogConfirmLoading" type="primary" @click="onEditedConfirm">确 定</el-button>
            </div>
        </el-dialog>

  </div>
</template>

<script>
import CommonTable from "@/components/CommonTable";
import CommonTableColumn from "@/components/CommonTableColumn";
import {getLatestMonthDateArrayByDate} from "@/utils/time";
import { getTypeList,getStatusList } from "@/api/support";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";
import {deleteUser,resetPassword} from "@/api/user";
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";
import {generateDepartmentFinalIdArray} from "@/utils/data-generator";
    


export default {
  name: "Advertiser",
  // components: {LookMakePlanDialog, AdvertiserEditDialog, CommonTable},
  components: { CommonTable,  MyUploadFileFormItem,CommonTableColumn}, //,ProductEditDialog
  computed: { ...mapGetters(['userSettingRoleList','ul','companyRoleList', 'departmentList']) },
  data() {
    return { 
        editUserDialogFormVisible: false,
        editUserDialogConfirmLoading: false,
        loading: true,
        formInline: {
            keyWord: '',
        },

                editUserForm: {
                    userName: '',
                    name: '',
                    telNo: '',
                    mail: '',
                    departmentName: '',
                    departmentId: -1,
                    departmentIdArrayForForm: [],
                    isAdmin: false,
                    roleName: '',
                    roleId: '',

                },
                currentPage: 1,
                
                value: [],
                holdertext:'',
    };
  },
  async beforeMount() {
            this.loading = true;
            try {
                await this.$store.dispatch('user/getUserList', this.formInline.keyWord);
                
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
  },
        
watch: {
            // 'editUserForm.roleId'(newRoleId) {
            //     this.editUserForm.roleName = this.$store.state.user.roleList.find(item => newRoleId === item.roleId)?.roleName ?? '';
            // },
},
    async created(){
        await this.$store.dispatch('user/getUserDepartmentList');
        // await this.$store.dispatch("operate/supplierList");
        await this.$store.dispatch('user/getUserRoleList',{});
    },
    
  methods: {

    onAddUser() {
        this.handleEdit({});
    },
    
    async handleEdit(item) {
        // await this.$store.dispatch('user/companyRoleList');
       
        // if (item){            
        //     this.value = JSON.parse('['+item.companyRole+']');
        // }
        this.editUserForm.departmentIdArrayForForm= []
        if (item) {

            item.departmentIdArrayForForm = generateDepartmentFinalIdArray({
                departmentId: item.departmentId,
                departmentList: this.departmentList
            });
            this.holdertext=item.companyName;
        }else{
            this.holdertext='请选择';
        }
        // item.departmentIdArrayForForm=[['1', '4', '15', '56']];//JSON.parse(JSON.stringify(item.departmentIdArrayForForm));
        // console.log('value',item.departmentIdArrayForForm);
        // console.log('companyRoleList', this.companyRoleList);
        // console.log('a1',item.departmentIdArrayForForm,item.departmentId,this.departmentList)
        // console.log('a2',this.departmentList)
        
        this.editUserDialogFormVisible = true;
        this.editUserForm = {...item};
        // 
        // this.$refs.dept.$forceUpdate();
    },

    onDepartmentListItemChooseChange(newArray) {
				this.editUserForm.departmentIdArrayForForm = newArray;
                this.editUserForm.departmentId = newArray[newArray.length - 1];
            },

            async onEditedConfirm() {
                
                if (!this.editUserForm.userName?.trim()) {
                    this.$message.warning('请输入用户名');
                    return;
                }

                // if (this.editUserForm.departmentIdArrayForForm.length<1) {
                //     this.$message.warning('请选择部门');
                //     return;
                // }
                    
                        // console.log('vv',this.value)
                if (!this.editUserForm.roleId){//this.value.length!=2){
                    this.$message.warning('请选择角色');
                    return;
                }

                // console.log('zhi',this.editUserForm.departmentIdArrayForForm,this.editUserForm.departmentIdArrayForForm[this.editUserForm.departmentIdArrayForForm.length-1],this.editUserForm.roleId);
                // return;
                
                this.editUserForm.companyId=this.editUserForm.departmentIdArrayForForm[this.editUserForm.departmentIdArrayForForm.length-1];
                
                this.editUserForm.roleId=this.editUserForm.roleId;
                        
                this.editUserDialogConfirmLoading = true;                

                if (!this.editUserForm.userId) {
                    this.editUserForm.userId='';
                }

                // console.log('this.editUserForm',this.editUserForm);

                try {
                    console.log('a1')
                    await this.$store.dispatch('user/setUserModify', {
                        ...this.editUserForm,
                        isAdmin: Number(this.editUserForm.isAdmin),
                    });
                    console.log('a2')
                    this.editUserDialogFormVisible = false;
                    // Message({message: '保存成功', type: 'success'})
                    this.$message.success('保存成功');
                } catch (e) {
                    // Message({message: '保存失败，请重试', type: 'error'})
                    this.$message.error('保存失败，请重试');
                    console.error(e);
                } finally {
                    this.editUserDialogConfirmLoading = false;
                }
				await this.onSearchByKeyWord();
			},

            async handleResetPassword(target) {
                // console.log('handleResetPassword', target);
                await this.$confirm('是否确认重置密码', '重置密码', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                try {
                    await resetPassword({uName: target.userName, newPswd: '123456'});
                } catch (e) {
                    this.$message({type: "error", message: '重置失败，请重试'});
                    console.error(e);
                }

                this.$message({type: "success", message: '重置成功'});
            },

            async handleDelete(target) {               
                await this.$confirm('是否确定删除该用户', '删除用户', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                try {
                    await deleteUser({userId: target.userId, optUserId: this.$store.state.user.userId});
                } catch (e) {
                    this.$message({type: "error", message: '删除失败，请重试'});
                    console.error(e);
                }
                this.$message({type: "success", message: '删除成功'});
				await this.onSearchByKeyWord();
			},
           
            async onSearchByKeyWord() {
                this.loading = true;
                await this.$store.dispatch('user/getUserList', this.formInline.keyWord);
                this.loading = false;
            },
           
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            }

  },
};
</script>

<style scoped>
.tableTitle {
    position: relative;
    margin: 30px auto;
    width: 90%;
    height: 2px;
    background-color: #d4d4d4;
    text-align: center;
    font-size: 16px;
    color: rgba(101, 101, 101, 1);
  }
 .midText {
    position: absolute;
    left: 50%;
    background-color: #ffffff;
    padding: 0 15px;
    transform: translateX(-50%) translateY(-50%);
  }
</style>